<template>
  <div>
    <b-row class="match-height">
      <b-col cols="8">
        <b-card title="Basic Info">
          <b-row>
            <b-col cols="4">
              <b-form-group label="Customer" label-for="brand-select">
                <v-select
                  @search="fetchUsers"
                  placeholder="Enter Customer Phone Number"
                  v-model="jobsheet.user"
                  :options="usersOptions"
                  label="phone"
                >
                  <template v-slot:no-options>
                    <div>
                      <div>
                        No Users found.
                      </div>
                      <b-button
                        @click="initNewCustomerModal"
                        size="sm"
                        variant="primary"
                        class="mt-1"
                        >Create New User
                        <feather-icon icon="PlusIcon"></feather-icon>
                      </b-button>
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Brand" label-for="brand-select">
                <v-select
                  @input="fetchModels"
                  :loading="loading.brands"
                  placeholder="Select Brand"
                  v-model="jobsheet.brand"
                  :options="brands"
                  label="name"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="5">
              <b-form-group label="Model" label-for="model-select">
                <v-select
                  @input="fetchIssues"
                  :loading="loading.models"
                  placeholder="Select Model"
                  v-model="jobsheet.model"
                  :options="models"
                  label="name"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card title="Repair Mode">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Select Repair Mode">
                <b-form-select
                  :disabled="!jobsheet.user"
                  @change="handleModeChange"
                  v-model="jobsheet.mode"
                  :options="orderModeOptions"
                />
              </b-form-group>

              <div
                class="mt-1"
                v-if="jobsheet.mode && jobsheet.mode != 'Walk-in'"
              >
                <b-form-select
                  placeholder="Select Address"
                  class="address-select"
                  v-model="jobsheet.address"
                  :options="addresses"
                ></b-form-select>
                <div>
                  <b-button
                    class="mt-1"
                    @click="showAddAddressModal"
                    variant="outline-primary"
                    block
                    >Add Address <feather-icon icon="PlusIcon"></feather-icon>
                  </b-button>
                </div>
              </div>

              <div
                class="mt-1"
                v-if="jobsheet.mode && jobsheet.mode != 'Walk-in'"
              >
                <b-row>
                  <b-col cols="6" class="pr-0">
                    <b-form-datepicker
                      id="repair-datepicker"
                      v-model="serviceDate.date"
                      :min="minDate"
                      placeholder="Choose a date"
                      :date-format-options="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      }"
                    ></b-form-datepicker>
                  </b-col>
                  <b-col cols="6" class="pl-0">
                    <b-form-timepicker
                      minutes-step="15"
                      placeholder="Choose a time"
                      v-model="serviceDate.time"
                      locale="en"
                    ></b-form-timepicker>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="8">
        <b-card title="Issues">
          <div v-if="this.jobsheet.model">
            <b-overlay>
              <b-form-checkbox-group
                id="issues-checkbox"
                v-model="selectedIssues"
                name="selected-issues-checkbox"
                class="demo-inline-spacing"
              >
                <b-row class="mt-0">
                  <b-col
                    cols="3"
                    v-for="issue in issues"
                    :key="issue.issue"
                    class="my-1"
                  >
                    <div class="issue-card">
                      <b-form-checkbox :value="issue">
                        <b-avatar size="sm" :src="issue.icon"></b-avatar>
                        {{ issue.name }}
                        <div>
                          <strong>₹ {{ issue.price }}</strong>
                        </div>
                      </b-form-checkbox>
                    </div>
                  </b-col>
                </b-row>
              </b-form-checkbox-group>
            </b-overlay>
          </div>
          <div class="text-center" v-else>
            <b-alert variant="warning" show>
              <div class="alert-body">
                <span> Select a device model to list the issues</span>
              </div>
            </b-alert>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card title="Selected Issues">
          <div class="selected-problem-list" v-if="selectedIssues.length">
            <div
              class="selected-problem"
              v-for="problem in selectedIssues"
              :key="problem.issue"
            >
              <p class="mb-0 issue-name">{{ problem.name }}</p>
              <p class="mb-0 issue-price">₹{{ formatPrice(problem.price) }}</p>
            </div>
          </div>
          <div v-else>
            <b-alert variant="warning" show>
              <div class="alert-body">
                <span> Select issues</span>
              </div>
            </b-alert>
          </div>

          <b-card-footer v-if="selectedIssues.length" class="px-0">
            <div class="d-flex justify-content-between">
              <div>
                <h4>Total</h4>
              </div>
              <div>
                <h4>Rs {{ formatPrice(getTotal) }}</h4>
              </div>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <div class="text-center">
      <b-button
        :disabled="continueDisabled"
        @click="createJobSheet"
        variant="primary"
      >
        <b-spinner small v-if="loading.creatingJobSheet"></b-spinner> Continue
        <feather-icon icon="ArrowRightIcon"></feather-icon>
      </b-button>
    </div>

    <b-modal
      no-close-on-backdrop
      v-model="newCustomerModal"
      id="new-customer-modal"
      content-class="shadow"
      title="Create New Customer"
      no-fade
      ok-only
      ok-title="Create User"
      @ok="createNewCustomer"
    >
      <b-overlay :show="creatingUser">
        <b-card-text>
          <validation-observer ref="newUserForm">
            <b-form-group label="Phone Number">
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    +91
                  </b-input-group-prepend>
                  <b-form-input
                    placeholder="Enter without +91"
                    v-model="newUser.phone"
                  ></b-form-input>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  placeholder="Enter Name"
                  v-model="newUser.name"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-card-text>
      </b-overlay>
    </b-modal>

    <b-modal
      id="add-address-modal"
      title="Add New Address"
      no-close-on-backdrop
      centered
      ok-only
      ok-title="Save Address"
      @ok="saveAddress"
      v-model="showAddAddress"
    >
      <b-card-text>
        <b-form-group label="Full name:">
          <b-form-input
            type="text"
            v-model="address.name"
            placeholder="Enter your full name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Mobile number"
          description="May be used to assist delivery"
        >
          <b-form-input
            type="text"
            v-model="address.phone"
            placeholder="10 digit mobile number without prefixes"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="PIN code:">
          <b-form-input
            placeholder="6 digits [0-9] PIN code"
            v-model="address.pincode"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Flat, House no., Building, Company, Apartment">
          <b-form-input v-model="address.building" required></b-form-input>
        </b-form-group>

        <b-form-group label="Area, Colony, Street, Sector, Village">
          <b-form-input v-model="address.area" required></b-form-input>
        </b-form-group>

        <b-form-group label="Landmark">
          <b-form-input
            v-model="address.landmark"
            placeholder="E.g. Near AIIMS Flyover, Behind Regal Cinema, etc."
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Town/City">
          <b-form-input v-model="address.city" required></b-form-input>
        </b-form-group>

        <b-form-group label="State / Province / Region">
          <b-form-select
            v-model="address.state"
            :options="states"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Address Type">
          <b-form-select
            v-model="address.addressType"
            :options="['Home', 'Office/Commercial']"
          ></b-form-select>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BCardText,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BFormSelect,
  BFormDatepicker,
  BFormTimepicker,
  BFormCheckboxGroup,
  BFormCheckbox,
  BAvatar,
  BAlert,
  BCardFooter,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import { required } from "@validations";
import commaNumber from "comma-number";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BButton,
    BModal,
    BCardText,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    BFormCheckboxGroup,
    BFormCheckbox,
    BAvatar,
    BAlert,
    BCardFooter,
    BSpinner,
  },
  data() {
    return {
      usersOptions: [],
      brands: [],
      models: [],
      orderModeOptions: [
        { value: null, text: "Select Repair Mode" },
        { value: "Walk-in", text: "Walk In" },
        { value: "Pickup & Drop", text: "Pickup & Drop" },
        { value: "Doorstep", text: "Doorstep" },
      ],
      states: [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      ],
      addresses: [],
      address: {},
      required,
      showAddAddress: false,
      jobsheet: {
        user: null,
        brand: null,
        model: null,
        mode: null,
        address: null,
        date: "",
        time: "",
      },
      newUser: {
        phone: "",
        name: "",
      },
      newCustomerModal: false,
      creatingUser: false,
      loading: {
        brands: false,
        models: false,
        creatingJobSheet: false,
      },
      serviceDate: {
        date: "",
        time: "",
      },
      selectedIssues: [],
      issues: [],
    };
  },
  computed: {
    minDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return new Date(today);
    },
    getTotal() {
      let total = 0;
      for (let i = 0; i < this.selectedIssues.length; i++) {
        var { price } = this.selectedIssues[i];
        total = total + price;
      }
      return total;
    },
    continueDisabled() {
      return false;
    },
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    fetchUsers(phone, loading) {
      loading(true);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/user/search`,
          {
            phone,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.usersOptions = response.data;
          loading(false);
        })
        .catch((error) => {
          loading(false);
          console.log(error.response.data);
        });
    },
    fetchModels(data) {
      this.jobsheet.model = "";
      this.getModels(data.slug);
    },
    fetchIssues(data) {
      let model = data.slug;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/model/get-model/${model}`)
        .then((response) => {
          let issues = response.data.issues;
          issues = issues.map((issue) => {
            let data = {
              issue: issue.issue._id,
              name: issue.issue.name,
              price: issue.price,
              icon: issue.issue.icon,
            };
            return data;
          });
          this.issues = issues;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    getBrands() {
      this.loading.brands = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/brand/get`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.loading.brands = false;
          this.brands = response.data;
        })
        .catch((error) => {
          this.loading.brands = false;
          console.log(error.response.data);
        });
    },
    getModels(brand) {
      this.loading.models = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/brand/models/${brand}`)
        .then((response) => {
          this.loading.models = false;
          this.models = response.data.models;
        })
        .catch((error) => {
          this.loading.models = false;
          console.log(error.response.data);
        });
    },
    initNewCustomerModal() {
      this.newCustomerModal = true;
    },
    createNewCustomer(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.creatingUser = true;
      this.$refs.newUserForm.validate().then((success) => {
        if (success) {
          let { phone, name } = this.newUser;
          if (phone && phone.length === 10) {
            phone = `+91${phone}`;
          } else {
            this.creatingUser = false;
            return alert("Enter a valid phone number!");
          }

          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/api/user/create/from-admin`,
              {
                phone,
                name,
              },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              let user = response.data.user;
              let data = {
                _id: user._id,
                name: user.name,
                phone: user.phone,
              };
              this.usersOptions.push(data);
              this.creatingUser = false;
              this.newCustomerModal = false;
            })
            .catch((error) => {
              this.creatingUser = false;
              console.log(error.response.data);
            });
        } else {
          this.creatingUser = false;
        }
      });
    },
    handleModeChange(changedValue) {
      if (changedValue != "Walk-in") {
        this.getAddress();
      }
    },
    showAddAddressModal() {
      this.showAddAddress = true;
    },
    getAddress() {
      if (!this.jobsheet.user || !this.jobsheet.user._id) {
        return this.makeToast("danger", "Error", "Select a user first!");
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/address`,
          {
            user: this.jobsheet.user._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let addresses = [{ value: null, text: "Select Address" }];
          for (let i = 0; i < response.data.length; i++) {
            const address = response.data[i];
            delete address.user;
            delete address.updatedAt;
            delete address._id;
            delete address.__v;
            const { name, building, pincode } = address;
            addresses.push({
              value: address,
              text: `${name}, ${building}, ${pincode}`,
            });
          }
          this.addresses = addresses;
        })
        .catch((error) => {
          console.log(error);
          this.makeToast("danger", "Error", `Error getting address!`);
        });
    },
    createNewAddress(address) {
      return new Promise((resolve, reject) => {
        if (!this.jobsheet.user || !this.jobsheet.user._id) {
          this.makeToast("danger", "Error", "Select a user first!");
          return reject();
        }
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/address/create-address`,
            {
              user: this.jobsheet.user._id,
              address: address,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject();
          });
      });
    },
    async saveAddress(bvModalEvt) {
      try {
        bvModalEvt.preventDefault();
        if (
          this.address.name &&
          this.address.phone &&
          this.address.pincode &&
          this.address.building &&
          this.address.area &&
          this.address.city &&
          this.address.state
        ) {
          this.address.phone = `+91${this.address.phone}`;
          const { name, building, pincode } = this.address;
          await this.createNewAddress(this.address);
          this.addresses.push({
            value: this.address,
            text: `${name}, ${building}, ${pincode}`,
          });
          this.jobsheet.address = this.address;
          this.showAddAddress = false;
        } else {
          this.makeToast(
            "danger",
            "Error",
            `Please fill all the required fields!`
          );
        }
      } catch (error) {
        this.makeToast(
          "danger",
          "Error",
          `Error while adding address! Please try again later.`
        );
      }
    },
    formatPrice(amount) {
      return commaNumber(amount);
    },
    formatServiceDate(date, time) {
      const combinedDateTimeStr = `${date}T${time}`;
      const generatedDate = new Date(combinedDateTimeStr);
      return generatedDate.toISOString();
    },
    createJobSheet() {
      let errors = [];
      if (!this.jobsheet.user) {
        errors.push("Select a user");
      }
      if (!this.jobsheet.brand) {
        errors.push("Select a brand");
      }
      if (!this.jobsheet.model) {
        errors.push("Select a model");
      }
      if (!this.jobsheet.mode) {
        errors.push("Select the repair mode");
      }
      if (
        this.jobsheet.mode &&
        this.jobsheet.mode != "Walk-in" &&
        (!this.serviceDate.date || !this.serviceDate.time)
      ) {
        errors.push("Select the repair date & time");
      }

      if (
        this.jobsheet.mode &&
        this.jobsheet.mode != "Walk-in" &&
        !this.jobsheet.address
      ) {
        errors.push("Select the address");
      }

      if (!this.selectedIssues.length) {
        errors.push("Select atleast an issue to continue");
      }

      if (errors.length) {
        for (let i = 0; i < errors.length; i++) {
          const errorMsg = errors[i];
          this.makeToast("danger", "Error", errorMsg);
        }
        return;
      }

      this.loading.creatingJobSheet = true;

      let jobSheet = {
        user: this.jobsheet.user._id,
        brand: this.jobsheet.brand._id,
        model: this.jobsheet.model._id,
        address: this.jobsheet.address,
        serviceDate: null,
        mode: this.jobsheet.mode,
      };
      jobSheet.problems = this.selectedIssues;

      if (this.jobsheet.mode != "Walk-in") {
        let updatedServiceDate = this.formatServiceDate(
          this.serviceDate.date,
          this.serviceDate.time
        );
        jobSheet.serviceDate = updatedServiceDate;
      }

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/order/admin/create-draft-order`,
          jobSheet,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.makeToast(
            "success",
            "Jobsheet Created",
            "The Jobsheet has been created successfully!"
          );
          this.loading.creatingJobSheet = false;
          this.$router.push(`/leads/${response.data.order._id}`);
        })
        .catch((error) => {
          this.makeToast(
            "danger",
            "Error",
            "Error creating the jobsheet! Please try again later!"
          );
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.getBrands();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.issue-card {
  background-color: hsla(214, 9%, 84%, 0.102);
  border-radius: 8px;
  cursor: pointer;
  height: 100%;
  padding: 10px;
}

.selected-problem-list,
.total-price-info {
  padding: 0 0;
}

.selected-problem,
.service-info-card,
.total-info-card {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.issue-name {
  font-size: 16px;
  font-weight: 400;
}
.issue-price {
  font-size: 17px;
  font-weight: bold;
}
</style>
